<template>
<div class="charting" style="height:100%; position:relative;">
    <el-col :xs="24" :sm="8" class="left-box" >
      <div>
        <div class="row-wrap" :style="{height: isMinScreen?(docHeight+40)+'px':docHeight+'px'}" style="margin-left:2px;">
          <div style="font-size: 15px;color: #303133;margin:5px 10px;">
              <el-link type="primary" @click="openCharting()" :underline="false" v-if="isMinScreen"
                style="padding-left:20px"
              >{{msg('打开输入界面')}}</el-link>
          </div>
          <el-input v-model="roleName" placeholder="点击选择组织" v-popover:rolesPopover suffix-icon="el-icon-arrow-down" :readonly="true">
            <el-button slot="append" icon="el-icon-search" @click="openSelectUser"></el-button>
          </el-input>
          <div style="border: 2px solid #DCDFE6;margin-top:15px;">
            <vxe-grid ref="dataTable" 
                :height="docHeight-200"
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="userData"
                :columns="columns"
            >
            </vxe-grid>
            <el-button style="margin-top:5px" @click="selectUser" type="primary" size="mini">确认选择</el-button>
          </div>
          <div style="border: 2px solid #DCDFE6;margin-top:20px;">
            <vxe-grid ref="dataTable1" 
                :height="docHeight-200"
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="selectUserData"
                :columns="columns1"
            >
          </vxe-grid>
          </div>
        </div>
      </div>
    </el-col>
    <!-- end 左边 -->

    <!-- 右边 -->
    <transition name="my-animate"  
            enter-active-class="animate__animated animate__fadeInRight animate__faster" 
            leave-active-class="animate__animated animate__fadeOutRight animate__faster">
    <el-col :xs="24" :sm="16" :class="['right-box', isMinScreen?'min-charting':'']" v-show="!isMinScreen || visiableCharting" >
        <div>
        <div class="row-wrap" style="display:flex; justify-content: flex-start; margin-right:5px;" >
          <div class="chart-title-wrap">
              <el-link :underline="false" type="primary" @click="closeCharting()" v-if="isMinScreen && visiableCharting">
                  <i class="el-icon-back font22" ></i>
              </el-link>
          </div>
            <el-button type="primary" size="mini" style="margin-left:5px" @click="doSend" v-if="hasPermission('notice:add')">
                <i class="iconfont icon-xunzhao03" style="display:inline-block;margin-right:4px;"></i>
                <transition name="el-fade-in-linear">
                    <span>{{msg('发布')}}</span>
                </transition>
            </el-button>
        </div>    
        <div :class="['row-wrap',isMinScreen?'ismin':'']" :style="{height: isMinScreen? (docHeight)+'px':(docHeight-46)+'px'}" style="margin-top:-5px;margin-right:5px;" >
          <div class="up-wrap">
            <el-input style="margin-bottom:20px;" v-model="title" placeholder="请输入标题"></el-input>
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              multiple
              :limit="3"
              :with-credentials="true"
              :on-preview="handlePreview"
              :on-remove="handRemoveFile"
              :on-success="handleUploadSuccess"
              :file-list="fileList">
              <el-button size="small" type="primary">{{msg('上传附件')}}</el-button>
              <div slot="tip" class="el-upload__tip" v-if="!isMinScreen">{{msg('最多只能上传三个文件')}}</div>
            </el-upload>
          </div>
          <div id="editorElem" ref="editor" style="text-align:left;">
          </div>
        </div>
      </div>
    </el-col>
    </transition>
    <el-popover
            ref="rolesPopover"
            placement="bottom"
            width="300"
            trigger="focus"
            v-model="rolesPopoverVisiable"
        >
        <!-- <el-scrollbar style="height:400px;">
            <el-tree :data="roles" default-expand-all node-key="id" 
                @node-click="selectedRoleNode">
            </el-tree>
        </el-scrollbar> -->
        <div>
            <el-input
                :placeholder="msg('输入关键字进行过滤')" clearable size="mini"
                    v-model="deptFilterText">
            </el-input>
            <el-scrollbar style="height:400px;">
                <el-tree :data="roles" node-key="id" 
                    ref="deptTreeRef"
                    :default-expanded-keys="expands"
                    :filter-node-method="filterNode"
                    @node-click="selectedRoleNode">
                </el-tree>
            </el-scrollbar>
        </div>
    </el-popover>

    <el-dialog :title="msg('搜索用户')" :visible.sync="exportVisiable" :fullscreen="isMinScreen"  @opened="openExportDg"
        :close-on-click-modal="false"
            width="400px" >
        <notice-send-dialog ref="exportDg" @close-dg="closeTihuoDg"/>
    </el-dialog>

</div>
</template> 

<script>
import 'animate.css';
import E from 'wangeditor'
import CtrlBox from "../../components/CtrlBox.vue";
import NoticeSendDialog from "./NoticeSendDialog.vue";
 
export default {
  name: 'role',
  components: {
    NoticeSendDialog:NoticeSendDialog
  },
  mounted:function(){
    var _this = this;
    this.initEditor();
    this.getRoles();
    this.visiableCharting = this.mi
  },
  data(){
    return{
        query:{  //查询条件
        },
        origins: [],
        curr: null,
        parent: null,
        visiableCharting: true, //是否打开聊天窗口
        roles: [],
        editorContent: '',
        fileList:[],
        uploadUrl: this.$kit.api.upload+"?type=notice&auth=1&press=0",
        title:null,
        editorRe:null,
        rolesPopoverVisiable:false,
        roleName:null,
        roleId:null,
        userData:[],
        selectUserData:[],
        deptFilterText: null,
        expands:[],
        showType:1,
        exportVisiable:false,
        columns:[
              {type: 'checkbox', minWidth: 60 },
              {field: "fullname", title: this.msg("姓名"), minWidth: 120, sortable: true,
                  filters:[{data: null}],
                  filterRender:{name: 'FilterInput'}
              },
              {field: "roleLabel", title: this.msg("角色"), minWidth: 100, sortable: true,
                  filters:[{data: {vals: [], sVal: ''}}],
                  filterRender:{name: 'FilterContent'}
              },
              {field: "tag", title: this.msg("组别"), minWidth: 100, sortable: true,
                  filters:[{data: null}],
                  filterRender:{name: 'FilterInput'}
              },
          ],
        columns1:[
                { type: 'seq', width: 50 },
                {field: "fullname", title: this.msg("姓名"), minWidth: 120, sortable: true},
                {field: "roleLabel", title: this.msg("角色"), minWidth: 100, sortable: true},
                {field: "tag", title: this.msg("组别"), minWidth: 100, sortable: true,
                  filters:[{data: null}],
                  filterRender:{name: 'FilterInput'}
              },
                {
                  title: '移除',
                  showOverflow: true,
                  minWidth: 80,
                  slots: {
                    // 使用渲染函数
                    default: ({ row }, h) => {
                      return [
                        h('button', {
                          style: {
                            color: 'blue'
                          },
                          on: {
                            click: () => this.deleteRow(row)
                          }
                        }, "移除")
                      ]
                    }
                  }
                },
            ],
    }
  }, 
  watch: {
        deptFilterText(val) {
            this.$refs.deptTreeRef.filter(val);
        }
    },
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          var is = this.$store.state.isMinScreen;
          if(!is){
              this.openCharting(is);
          }
          return is;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 130;
        }
    },
    routeTab: {
        get(){
            try{
                return this.$store.state.activeMenus[this.$route.path]
            }catch{
                return null;
            }
        }
    }
  },
  methods: {
    openCharting(is){ //打开右侧窗口
        setTimeout(()=>{
          var height = 0;
          let isMin = this.$store.state.isMinScreen;
          if(isMin){
            height = (this.docHeight - 350) + "px";
          }else{
            height = (this.docHeight - 300) + "px";
          }
          this.$refs.editor.querySelector(".w-e-text-container").style.height=height;
        },200)
        this.visiableCharting = true;
    },
    closeCharting(){ //关闭右侧窗口
        this.visiableCharting = false; 
    },
    deleteRow(row) {
        let index = this.$refs.dataTable1.getRowIndex(row);
        this.selectUserData.splice(index,1);
    },
    selectedRoleNode(role, node, self){
        this.roleName = role.label;
        this.roleId = role.id,
        this.rolesPopoverVisiable = false;
        if(this.roleName && this.roleId){
          var _this = this;
          this.$axios.get(this.$kit.api.notice.findUserByDeptId+"?path="+role.path)
              .then((result) => {
                if(result.status){
                      this.userData.splice(0,this.userData.length);
                      let data= result.data;
                      if(data.length>0){
                          data.forEach(e=>{
                            this.userData.push({
                              id:e.id,
                              fullname: e.fullname,
                              roleLabel: e.roleLabel,
                              username: e.username,
                              tag: e.userTag
                            })
                          })
                      }
                  }
              });
        }else{
          this.$message(this.msg('请选择组织！'));
        }
    },
    filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
    selectUser(){//确认选择
        // var selectes = this.$refs.multipleTable.selection;
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length > 0){
            this.selectUserData.push(...selectes);
            var arr2 = this.selectUserData.filter((x, index,self)=>{
                var arrids = []
                this.selectUserData.forEach((item,i) => {
                  arrids.push(item.id)
                })
                return arrids.indexOf(x.id) === index
            })
            this.selectUserData.splice(0,this.selectUserData.length);
            this.selectUserData = arr2;
        }else{
          this.$message(this.msg('请选择用户！'));
        }
          // this.selectUserData = this.unique(this.selectUserData);
    },
    unique(arr){
      return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
    },
    initEditor(){
      var _this = this;
      var editor = new E(this.$refs.editor)
      editor.customConfig.onchange = (html) => {
        this.editorContent = html
      }
      //设置上传图片是跨域
      editor.customConfig.withCredentials = true;
      // 配置服务器端地址
      editor.customConfig.uploadImgServer = this.uploadUrl;
      //插入图片
      editor.customConfig.uploadImgHooks = {
        customInsert: function (insertImg, result, editor) {  
          if(result.status){
          // 上传代码返回结果之后，将图片插入到编辑器中
            insertImg(_this.$kit.api.imgView+result.data)
          }
        },
      }
      editor.customConfig.customAlert = function (info) {
          _this.$message(info);
      }
      editor.create();
      this.editorRe = editor;
    },
    getRoles(){
        this.$axios.post(this.$kit.api.dept.listByDataPath, this.query)
            .then((result) => {
                if(result.status){
                    var data = result.data;
                    var rs = this.$kit.buildTree(data, true);
                    this.roles = rs;
                    this.expands = [];
                    this.getExpands(this.roles);
                    // this.origins = data;
                    // this.buildTree(data);
                }else{
                    this.$message(result.msg);
                }
            });
    },
    getExpands(depts){
            depts.forEach(e => {
                if(e.children && e.children.length<=10){
                    this.expands.push(e.id);
                    this.getExpands(e.children);
                }
            });
        },
    doSend(){
      if(!this.editorContent && !this.title){
          this.$message(this.msg('请输入标题和内容！'));
          return;
      }

      if(!this.selectUserData.length > 0){
        this.$message(this.msg('请选择用户！'));
        return;
      }

      if(!this.showType){
        this.$message(this.msg('请选择显示方式！'));
        return;
      }

      //获取数据，构建对象
      let title = this.title;//标题
      let content = this.editorContent;//内容
      let attachmentIds = [];//附件ID
      let userIds = [];//用户ID

      let overview = this.editorRe.txt.text();
      if(overview.length>50){
        overview = overview.substring(0, 45)+"....";
      }

      for(let i=0;i<this.fileList.length;i++){
        attachmentIds.push(this.fileList[i].attachmentId);
      }

      for(let i=0;i<this.selectUserData.length;i++){
        userIds.push(this.selectUserData[i].id);
      }

      let notice = {
        title:title,
        content:content,
        overview:overview
      }
      notice.attachmentIds = attachmentIds;
      notice.userIds = userIds;
      
      var _this = this;
      this.$axios.post(this.$kit.api.notice.send, notice)
          .then(function(result){
              if(result.status){
                  _this.$message(result.msg || _this.msg('发送成功'));
                  _this.getRoles(true);
                  _this.clearNotice();
              }else{
                  _this.$message(result.msg || _this.msg('发送失败'));
              }
              
          });

    },
    clearNotice(){
      var _this = this;
      _this.title = null;//标题
      _this.fileList.splice(0,_this.fileList.length);
      _this.editorRe.txt.clear();
      _this.roleName = null;
      _this.roleId = null;
      _this.userData.splice(0,_this.userData.length);
      _this.selectUserData.splice(0,_this.selectUserData.length);
    },
    //上传成功后的回调
    handleUploadSuccess(res, file){
        var _this = this;
        if(res.status){
            _this.fileList.push({
                name: file.name,
                attachmentId: res.data,
            });
        }
    },
    //下载附件
    handlePreview(file){
        window.location.href= this.$kit.api.imgView+file.attachmentId;
    }, 
    //处理删除文件
    handRemoveFile(file, fileList){
        var index = this.fileList.findIndex(f => f.name == file.name);
        if(index>-1){
            this.fileList.splice(index, 1);
        }
    },
    openSelectUser(){
      this.exportVisiable = true;
    },
    openExportDg(){
      this.$nextTick(() => {
          this.$refs.exportDg.init();
      });
    },
    closeTihuoDg(data){
        this.exportVisiable = false;
        if(data.names){
          this.$axios.post(this.$kit.api.notice.findUserByNames,data.names)
            .then((result) => {
                if(result.status){
                    let data= result.data;
                    this.userData.splice(0,this.userData.length);
                    if(data.length>0){
                        data.forEach(e=>{
                          this.userData.push({
                            id:e.id,
                            fullname: e.fullname+'('+e.username+')',
                            roleLabel: e.roleLabel,
                            username: e.username,
                            tag: e.userTag
                          })
                        })
                    }
                }
            });
        }
    },
  }
}
</script>

<style lang="scss">
/*
.w-e-text-container{ 
    height:513px !important;
} 
.ismin .w-e-text-container{ 
    height:400px !important;
} 
*/
.w-e-menu{
    z-index: 2 !important;
}
.w-e-text-container{
    z-index: 1 !important;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
.up-wrap{
      margin: 15px 5px;
      width: 60%;
}
.min-charting{
    position: absolute; top:0px; left:0px; z-index: 10;
}
.chart-title-wrap{display:flex;  align-items: center;box-sizing: content-box;
}
.charting{
    margin-left: 1px;
    margin-right: -7px;
    padding-right: 5px;
}
</style>
