<template>
<div>
    <h2>{{msg('请输入账号')}}</h2>
    <el-input type="textarea"  :rows="10" :placeholder="msg('please input names')" 
        v-model="names" style="margin-top:0px;">
    </el-input>

    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" @click="click">{{msg('取消')}}</el-button>
    </div>
</div> 
</template>

<script>
export default {
  name: 'ExpQuery',
  data(){
    return{
        names: null,
    }
    
  }, 
  mounted: function(){
  },
  props:{
  },
  methods:{
    init(){
        this.names = null;
    },
    onSubmit(){
        if(!this.names){
            this.$message(this.msg('请输入账号'));
            return;            
        }

        let cs = this.names.split(/\s+/);
        
        let css = [];
        for(let i=0; i<cs.length; i++){
            if(cs[i]) css.push(cs[i]);
        }
        
        if(css.length==0){
            this.$message(this.msg('请输入有效数据'));
            return;
        }

        this.$emit("close-dg", {names:  css?css.join(","):''})

    },
    click(){
        this.$emit("close-dg", {names:  ''});
    }

  }
}
</script>

<style scoped lang="scss">


</style>
